import React, { useState, useRef, useEffect } from "react";
import { HiOutlineRefresh, HiOutlineX, HiPaperAirplane } from "react-icons/hi";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles


function ChatbotSettings() {
    const [title, setTitle] = useState("Bank of Bhutan");
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [initialMessage, setInitialMessage] = useState("Welcome! How can I help you?");
    const messagesEndRef = useRef(null);
    const [suggestedMessage, setSuggestedMessage] = useState('');
    const [messageBoxColor, setMessageBoxColor] = useState('#ffffff');
    const [userTextColor, setUserTextColor] = useState('#000000');
    const [botTextColor, setBotTextColor] = useState('#000000');
    // const [chatBubbleImage, setChatBubbleImage] = useState("/profile.png");
    const [logoImage, setLogoImage] = useState("/profile.png");
    // const [iconFile, setIconFile] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [saving, setSaving] = useState(false); // State for saving indicator

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings`);
            const settings = response.data;
            if (settings) {
                setTitle(settings.title || "Bank of Bhutan");
                setInitialMessage(settings.initialMessage || "Welcome! How can I help you?");
                setSuggestedMessage(settings.suggestedMessages.join('\n') || '');
                // setChatBubbleImage(settings.iconImage || "/profile.png");
                setLogoImage(settings.logoImage || "/profile.png");
                setMessageBoxColor(settings.chatBoxColor || '#ffffff');
                setUserTextColor(settings.userTextColor || '#000000');
                setBotTextColor(settings.botTextColor || '#000000');
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    const suggestedMessagesArray = suggestedMessage.split('\n').filter(msg => msg.trim() !== '');

    const handleRefresh = () => {
        setMessages([]);
    };

    const sendMessage = () => {
        if (input.trim() === "") return;

        const newMessage = {
            text: input,
            sender: "user",
        };

        setMessages([...messages, newMessage]);
        setInput("");
        setIsLoading(true);

        setTimeout(() => {
            const botReply = {
                text: "This is a simulated bot response.",
                sender: "bot",
            };
            setMessages((prevMessages) => [...prevMessages, botReply]);
            setIsLoading(false);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    };

    const handleSave = async () => {
        setSaving(true); // Set saving state to true
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('initialMessage', initialMessage);
            formData.append('suggestedMessages', JSON.stringify(suggestedMessage.split('\n').filter(msg => msg.trim() !== '')));
            formData.append('chatBoxColor', messageBoxColor);
            formData.append('userTextColor', userTextColor);
            formData.append('botTextColor', botTextColor);

            // if (iconFile) {
            //     formData.append('iconImage', iconFile);
            // }
            if (logoFile) {
                formData.append('logoImage', logoFile);
            }

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('Settings saved successfully!', { className: 'bg-green-500 text-white' }); // Display success message
            fetchSettings();
        } catch (error) {
            console.error('Error saving settings:', error);
            toast.error('Error saving settings. Please try again.', { className: 'bg-red-500 text-white' }); // Display error message
        } finally {
            setSaving(false); // Reset saving state
        }
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // const handleIconUpload = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setIconFile(file);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setChatBubbleImage(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    const renderMessage = (message) => (
        <div
            className={`p-3 rounded-lg ${message.sender === "user"
                ? `bg-${messageBoxColor} text-${userTextColor} self-end`
                : `bg-${messageBoxColor} text-${botTextColor} self-start`
                }`}
            style={{ backgroundColor: message.sender === "user" ? messageBoxColor : '#f3f4f6', color: message.sender === "user" ? userTextColor : botTextColor }}
        >
            {message.text}
        </div>
    );

    return (
        <div className="flex w-full h-full">
            <div className="flex w-full h-full">
                {/* Toast Container */}
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick draggable pauseOnHover />

                {/* Settings Panel */}
                <div className="flex flex-col w-2/3 p-4 border-r bg-gray-100">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-semibold mb-4">Setting Panel</h2>
                        <button onClick={handleSave} className={`bg-black text-white w-[80px] h-[30px] rounded-md ${saving ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={saving}>
                            {saving ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                    <div className="mb-4">
                        <p className="text-xs text-gray-500 mt-1">Recommended: Refresh your web browser once you reach the page to ensure accurate data.</p>
                    </div>
                    {/* Title Input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full p-2 border rounded-md"
                            placeholder="Enter Chatbot Title"
                        />
                    </div>

                    {/* Initial Message Input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Initial Message:</label>
                        <textarea
                            value={initialMessage}
                            onChange={(e) => setInitialMessage(e.target.value)}
                            className="w-full p-2 border rounded-md"
                            placeholder="Enter initial message"
                        ></textarea>
                    </div>

                    {/* Suggested Message Input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Suggested Messages:</label>
                        <textarea
                            value={suggestedMessage}
                            onChange={(e) => setSuggestedMessage(e.target.value)}
                            className="w-full p-2 border rounded-md"
                            placeholder="Enter suggested messages (one per line)"
                            rows={4}
                        ></textarea>
                        <p className="text-xs text-gray-500 mt-1">Separate each message with a new line.</p>
                    </div>

                    {/* Icon and Logo Uploads */}
                    <div className="mb-4 flex justify-between">
                        {/* Icon Upload */}
                        {/* <div className="w-1/2 pr-2">
                            <label className="block text-sm font-medium mb-1">Upload Icon:</label>
                            <input
                                type="file"
                                onChange={handleIconUpload}
                                className="w-full p-2 border rounded-md"
                                accept="image/*"
                            />
                        </div> */}

                        {/* Logo Upload */}
                        <div className="w-1/2 pl-2">
                            <label className="block text-sm font-medium mb-1">Upload Logo:</label>
                            <input
                                type="file"
                                onChange={handleLogoUpload}
                                className="w-full p-2 border rounded-md"
                                accept="image/*"
                            />
                            <p className="text-xs text-gray-500 mt-1">Upload image to show on chat UI as Profile Picture</p>
                        </div>
                    </div>

                    {/* Message Box Color Inputs */}
                    <div className="mb-4 flex justify-between">
                        {/* User Message Box Color */}
                        <div className="w-1/2 pr-2">
                            <label className="block text-sm font-medium mb-1 mr-2">Message Color:</label>
                            <div className="flex items-center">
                                <input
                                    type="color"
                                    value={messageBoxColor}
                                    onChange={(e) => setMessageBoxColor(e.target.value)}
                                    className="w-8 h-8" // Ensure the input is circular
                                    style={{ backgroundColor: messageBoxColor }}
                                />
                            </div>
                        </div>

                        <div className="w-1/2 pr-2">
                            <label className="block text-sm font-medium mb-1 mr-2">Text Color:</label>
                            <div className="flex items-center">
                                <input
                                    type="color"
                                    value={userTextColor}
                                    onChange={(e) => setUserTextColor(e.target.value)}
                                    className="w-8 h-8" // Ensure the input is circular
                                    style={{ backgroundColor: userTextColor }}
                                />
                            </div>
                        </div>
                    </div>
                    <p className="text-xs text-gray-500 mt-1">To view text and message color changed, you must type and send message on chat UI.</p>
                </div>


                {/* Chatbot Preview */}
                <div className="flex w-[400px] h-[600px] items-center justify-center bg-gray-50 ml-4">
                    <div className="relative flex flex-col max-w-md w-full h-full bg-white rounded-2xl border shadow-lg">
                        {/* Header with Refresh and Close Buttons */}
                        <div className="flex justify-between items-center text-black/80 py-2 px-4 rounded-t-xl border-b">
                            <div className="flex items-center">
                                <img
                                    src={logoImage} // Use the state variable here
                                    alt="Logo"
                                    className="w-8 h-8 rounded-full mr-2"
                                />
                                <h1 className="text-[16px] font-semibold">{title}</h1>
                            </div>
                            <div className="flex items-center">
                                <button onClick={handleRefresh} className="flex items-center p-1 text-black/80 hover:text-gray-600 transition">
                                    <HiOutlineRefresh className="w-5 h-5" />
                                </button>
                                <button id="close-button" className="p-1 text-black/80 hover:text-gray-600 transition">
                                    <HiOutlineX className="w-6 h-6" />
                                </button>
                            </div>
                        </div>

                        {/* Chat Area */}
                        <div className="flex-grow overflow-auto p-4 space-y-4 flex flex-col">
                            <div className="w-fit mr-auto my-1 px-5 py-4 rounded-lg border bg-zinc-200 shadow-md">
                                {initialMessage}
                            </div>
                            {messages.map((message, index) => (
                                <div key={index} className="flex flex-col">
                                    {renderMessage(message)}
                                </div>
                            ))}
                            {isLoading && (
                                <div className="self-start px-4 py-2 bg-gray-100 text-gray-600 rounded-lg">
                                    Typing...
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>

                        {/* Suggestions Area */}
                        <div className="p-2 border-t bg-gray-100">
                            <div className="flex overflow-x-auto gap-2 scrollbar-hide">
                                {suggestedMessagesArray.map((suggestion, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setInput(suggestion)}
                                        className="bg-zinc-50 border text-black/80 rounded-md px-3 py-2 text-sm whitespace-nowrap hover:bg-zinc-200 transition"
                                    >
                                        {suggestion}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Input Area */}
                        <div className="flex items-center p-2 border-t">
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                className="flex-grow border rounded-lg p-2"
                                placeholder="Type a message..."
                            />
                            <button onClick={sendMessage} className="p-2 bg-blue-500 text-white rounded-lg ml-2">
                                <HiPaperAirplane className="transform rotate-90" />
                            </button>
                        </div>
                    </div>
                    {/* Chat Bubble Image */}
                    {/* <div className="absolute bottom-4 right-4 w-12 h-12 overflow-hidden rounded-full">
                        <img
                            src={chatBubbleImage} // Use the state variable
                            alt="Chat Bubble"
                            className="w-full h-full object-cover" // Ensures the image covers the container while maintaining aspect ratio
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ChatbotSettings;
